@import 'variables/variables-bootstrap';
@import 'base/mixins';

/* Boostrap overrides */
/* .container-fluid {
    padding-left: 0;
    padding-right: 0;
} */

/* Bootstraplike addons */
.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default !important;
}

.cursor-default * {
    cursor: default !important;
}

.textarea-no-resize {
    resize: none;
}

.overflow-x-clip {
    overflow-x: clip !important;
}